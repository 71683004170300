import React from "react"
import { Link as ReachLink, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Box, Heading, Link } from "@chakra-ui/core"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulBlogPost(sort: {fields: publishDate, order: DESC}) {
        edges {
          node {
            title
            slug
            detail {
              childMarkdownRemark {
                html
              }
            }
            publishDate
            heroImage {
              title
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }

      contentfulPage(unique: {eq: "IndexPage"}) {
        title
        description
        tags
        h1
        body {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title={data.contentfulPage.title} description={data.contentfulPage.description} keywords={data.contentfulPage.tags} />
      <Box>
        <Heading as="h1" mt={6} textAlign="center" lineHeight="2">{data.contentfulPage.h1}</Heading>
        <Box mt={2} id="index" color="gray.600" dangerouslySetInnerHTML={{ __html: data.contentfulPage.body.childMarkdownRemark.html }}></Box>
        <Box id="blog">
          {data.allContentfulBlogPost.edges.map((edge) => {
            return (
              <ReachLink to={`/blog/${edge.node.slug}`}>
                <Box py={4} display={{ md: "flex" }}>
                  <Box minW="300px" flexShrink="0">
                    <Img fluid={edge.node.heroImage.localFile.childImageSharp.fluid} style={{ 'margin-top': '16px' }} alt={edge.node.heroImage.title} />
                  </Box>
                  <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }}>
                    <Link as={ReachLink} mt={1} display="block" to={`/blog/${edge.node.slug}`}><Heading as="h2" size="lg">{edge.node.title}</Heading></Link>
                    <Box mt={2} color="gray.600" dangerouslySetInnerHTML={{ __html: edge.node.detail.childMarkdownRemark.html }}></Box>
                  </Box>
                </Box>
              </ReachLink>
            )
          })}
        </Box>
      </Box>
    </Layout>
  )
}

export default IndexPage
